import { TweenMax, gsap } from 'gsap/all';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { CSSPlugin } from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollToPlugin, CSSPlugin);
/**
 * Кнопка прокрутки страницы наверх.
 *
 * При прокрутке страницы вниз, внизу справа появляется
 * кнопка при клике на которой страница прокручивается вверх
 * к началу.
 *
 * Кнопка отображается при условии что размер окна больше
 * `600px` и пользователь прокрутил страницу на высоту большую
 * размеру видимого окна.
 *
 * @todo проверка отображения `isHidden()`. Сейчас анимация работает
 * все время при прокрутке, это неверно. Отображение и скрытие должно
 * срабатывать только в момент изменения условий, на границах.
 */
var ScrollUp = /** @class */ (function () {
    function ScrollUp(opts) {
        this.id = opts.id;
        this.button = document.createElement('div');
        this.minHeight = opts.minHeight || 600;
        this.speed = opts.speed || .5;
        this.bottom = opts.bottom || 20;
        this.right = opts.right || 30;
        this.debug = opts.debug === true;
        this.hidden = true;
        this.createButton();
    }
    /**
     * Создание HTML-элемента кнопки
     * и подключение на страницу
     */
    ScrollUp.prototype.createButton = function () {
        this.button.setAttribute('id', this.id);
        this.button.classList.add('button');
        this.button.innerHTML = '<span>&uarr; Наверх</span>';
        this.button.addEventListener('click', this.scrollToTop);
        document.body.append(this.button);
        this.createAnimations();
    };
    /**
     * Инициализация анимации
     */
    ScrollUp.prototype.createAnimations = function () {
        var _this = this;
        window.addEventListener('scroll', function () {
            if (window.pageYOffset < _this.minHeight) {
                _this.debugStyle('ignore');
                _this.hideButton();
                return;
            }
            if (document.documentElement.clientHeight > _this.minHeight
                && window.pageYOffset > document.documentElement.clientHeight) {
                _this.debugStyle('show');
                _this.showButton();
            }
            else {
                _this.debugStyle('hide');
                _this.hideButton();
            }
        });
    };
    ScrollUp.prototype.debugStyle = function (label) {
        if (!this.debug)
            return;
        console.log(document.documentElement.clientHeight, window.pageYOffset, this.minHeight, 'show:flag', window.pageYOffset > document.documentElement.clientHeight
            && document.documentElement.clientHeight > this.minHeight, 'ignore:flag', window.pageYOffset < this.minHeight);
        console.log(label, window.getComputedStyle(this.button).display, this.isHidden());
    };
    /**
     * показывает кнопку
     */
    ScrollUp.prototype.showButton = function () {
        var _this = this;
        // if (!this.hidden) return
        TweenMax.to(this.button, .5, {
            opacity: 1,
            right: this.right,
            bottom: this.bottom,
            position: 'fixed',
            display: 'flex',
            boxShadow: '0 10px 20px rgba(0,0,0,.2)',
            onComplete: function () {
                _this.hidden = false;
            }
        });
    };
    /**
     * скрывает кнопку
     */
    ScrollUp.prototype.hideButton = function () {
        var _this = this;
        // if (this.hidden) return
        TweenMax.to(this.button, .5, {
            opacity: 0,
            bottom: -100,
            right: this.right,
            display: 'none',
            boxShadow: 'none',
            onComplete: function () {
                _this.hidden = true;
            }
        });
    };
    ScrollUp.prototype.scrollToTop = function () {
        TweenMax.to(window, 1, { scrollTo: 0 });
    };
    /**
     * проверяет видимость элемента на странице
     */
    ScrollUp.prototype.isHidden = function () {
        return window.getComputedStyle(this.button).display === 'none';
    };
    return ScrollUp;
}());
export { ScrollUp };
export function start(id) {
    return new ScrollUp({ id: id });
}
