import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend, localize, configure } from "vee-validate";
import { required, email, max, length, min } from "vee-validate/dist/rules";
import ru from 'vee-validate/dist/locale/ru.json';
/**
 * @see https://logaretm.github.io/vee-validate/
 * @see https://logaretm.github.io/vee-validate/guide/state.html#flags
 *
 * Для сброса состояния `ValidationObserver` имеет метод `reset`.
 *
 * для того чтобы сбросить состояние формы используется к начальному
 * можно использовать ref на объекте ValidationObserver(ref="form")
 *
 * this.$refs.form.reset();
 *
 * Чтобы программно запустить проверку полей
 *
 * this.$refs.form.validate().then(success => {
 *   if (!success) {
 *     return
 *   }
 *
 *   ...
 *
 *   this.$nextTick(() => {
 *     this.$refs.form.reset()
 *   })
 * })
 *
 *
*/
localize('ru', ru);
configure({
    classes: {
        valid: 'is-success',
        invalid: 'is-danger',
    }
});
/**
 * register validation components globally
 */
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
extend('required', required);
extend('email', email);
extend('length', length);
extend('max', max);
extend('min', min);
// custom rules
/**
 * общая сумма не может быть меньше 3000
 */
extend('total', function (value) {
    if (value > 3000) {
        return true;
    }
    return 'Минимальная сумма заказа 3000 рублей';
});
/**
 * площадь в диапазоне 10 < 100
 */
extend('area', function (value) {
    if (value >= 10 && value < 100) {
        return true;
    }
    return "\u041E\u0431\u0449\u0430\u044F \u043F\u043B\u043E\u0449\u0430\u0434\u044C \u043F\u043E\u0442\u043E\u043B\u043A\u0430 \u0434\u043E\u043B\u0436\u043D\u0430 \u0431\u044B\u0442\u044C 10 - 100 \u043A\u0432.\u043C.\n    \u0414\u043B\u044F \u043F\u043B\u043E\u0449\u0430\u0434\u0438 100 \u0438 \u0431\u043E\u043B\u0435\u0435 \u043A\u0432.\u043C. \u0442\u0440\u0435\u0431\u0443\u0435\u0442\u0441\u044F \u0438\u043D\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043B\u044C\u043D\u044B\u0439 \u0440\u0430\u0441\u0447\u0435\u0442";
});
