import Vue from 'vue';
import FeedbackForm from "@/platform/components/feedback/FeedbackForm.vue";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";
export default Vue.extend({
    mixins: [FormTriggerMixin],
    methods: {
        openForm: function () {
            this.$buefy.modal.open({
                component: FeedbackForm,
                width: 640,
                /**
                 * всегда добавляем класс,
                 * чтобы иметь возможность
                 * переопределения стилей
                 * для форм в модальном окне
                 */
                customClass: 'form-in-modal',
                canCancel: ['x'],
                /**
                 * опция используется для определения
                 * что форма открыта в модальном окне,
                 * это не стандартная опция она добавлена
                 * специально в форму, и отслеживается
                 * при закрытии уведомления об успешной
                 * отправке
                 */
                props: {
                    trigger: true
                }
            });
        }
    }
});
