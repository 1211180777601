import Vue from 'vue'
import store from '@/store'
import { CallbackForm } from '@/platform/components'

import { gsap, TweenMax, TimelineMax } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(CSSRulePlugin, CSSPlugin, ScrollToPlugin, ScrollTrigger)

import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
// import * as indicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

/**
 * управление поведением `navbar`
 *
 * компонент: /local/components/platform/navbar
 *
 * Основная логика в том, что Vue "оборачивает"
 * существующий DOM-элемент. Это дает возможность
 * добавлять любой функционал в панель. Например добавлять
 * кнопки отрытия модальных окон с формами.
 *
 * Также функциональность самой панели находиться
 * в методах и "состоянии" Vue.
 *
 * Связь с общим хранилищем отрывает "безграничные" возможности
 * интеграции, например создание "корзины заказов".
 *
 * В новой версии вместо ScrollMagic используется gsap.scrollTrigger.
 * @see https://greensock.com/docs/v3/Plugins/ScrollTrigger
 */

function start () {
  /* eslint no-unused-vars: 0 */
  /**
   * подразумевается что на странице
   * только один элемент nav#navbar-top
   *
   * если необходимо инициализировать несколько панелей, то
   * надо использовать отдельные файлы/модули или инициализировать
   * панели в цикле (for)
   */
  new Vue({
    store,
    //router,
    el: 'nav#navbar-admiral',
    data() {
      return {
        active: false
      }
    },
    methods: {
      /**
       * добавление класса
       * к меню при клике на `burger`
       */
      toggle() {
        this.active = !this.active
      },
      callback () {
        /**
         * лучший вариант использовать
         * отдельные компоненты в модальном окне
         */
        const modal = this.$buefy.modal.open({
          parent: this,
          component: CallbackForm,
          canCancel: ['x'],
          width: 720
        })

        /**
         * после того, как данные успешно отправлены
         * на сервер, необходимо скрыть кнопку закрытия окна
         * вынудив пользователя нажать кнопку "ok", чтобы
         * явно сбросить настройки формы.
         *
         * Другой вариант полностью разрушать форму
         * при закрытии destroyOnHide: true
         */
        modal.$on('success', () => {
          // modal.$props.canCancel = false
        })
      }
    }
  })

  /**
   * scrollmagic
   *
   * для запуска функциональности добавить к nav.navbar
   * класс `navbar-sticky`. после прикрепления панели
   * добавляется класс `is-pinned`
   *
   */
  let sm_selector = '.navbar-sticky'
  let pinnedClass = 'is-pinned'
  const zIndex = 39
  const showIndicators =  false

  let navbar = document.querySelector(sm_selector)

  if (!navbar) return

  const controller = new ScrollMagic.Controller({})
  const menuSceneOffset = 450
  const menuScene = new ScrollMagic.Scene({
    triggerElement: sm_selector,
    triggerHook: 'onLeave',
    offset: menuSceneOffset,
    duration: 0
  })
    .setClassToggle(sm_selector, pinnedClass)
    .setPin(sm_selector)
    .on('enter', function(e) {
      TweenMax.to(sm_selector, 0.3, {
        duration: 0.3,
        y: menuSceneOffset - 2,
        zIndex: zIndex
      })
    })
    .on('leave', function(e) {
      TweenMax.to(sm_selector, 0.2, {
        y: 0,
        zIndex: zIndex
      })
    })

  if (showIndicators) {
    menuScene.addIndicators({
      name: 'STICKY',
      colorStart: 'green',
      colorEnd: 'red',
      colorTrigger: 'blue',
      indent: menuSceneOffset
    })
  }

  // async init
  setTimeout(function(){
    menuScene.addTo(controller)
  }, 100)
}

export default {
  start
}
