/**
 * функциональность сайта
 */
import { mountTrigger } from '@/site/components/order';
import { mountForm as mountFeedbackForm, mountTrigger as mountFeedbackTrigger } from '@/site/components/feedback';
import { mountForm as mountReservationForm, mountTrigger as mountReservationTrigger } from '@/site/components/reservation';
export var Site = {
    orderTriggers: mountTrigger('.js-order-form-trigger'),
    feedbackForm: mountFeedbackForm('#site-feedback-form'),
    reservationForm: mountReservationForm('#site-reservation-form'),
    feedbackTriggers: mountFeedbackTrigger('.js-feedback-form-trigger'),
    reservationTriggers: mountReservationTrigger('.js-reservation-form-trigger')
};
