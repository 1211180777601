"use strict";
/**
 * общие библиотеки
 */
require('@/custom/http');
require('@/custom/validate');
require('@/custom/buefy');
/**
 * Начинайте с самого простого,
 * держите файл простым, не подключайте лишнего.
 *
 * Чем меньше функциональности, тем лучше.
 * Различные примеры в `main.ts.example`.
 *
 * Создавайте стили, шаблон проекта сначала. И после
 * завершения основных работ переходите к js.
 *
 * Используйте shuffle.dev по максимуму, прежде чем
 * перейти к работе с Битрикс.
 *
 * Переносите и используйте только необходимый
 * функционал.
 *
 * При разработке выходите из админки. Это уменьшит
 * количество загружаемых ресурсов.
 *
 * - platform - готовые решения и примеры кода
 * - runtime - исполняемые скрипты (примеры)
 * - site - функционал сайта
 *
 */
require('./sass/main.sass');
// require('./platform')
require('./runtime');
require('./site');
