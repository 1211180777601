import { start as startBackgroundImages } from './background-images';
import { start as startScrollUp } from './scrollup';
import { start as startSwiperHero } from './swiper-hero';
import navbar from './navbar';
import pswp from './pswp';
/**
 * все runtime скрипты ожидают загрузки страницы
 */
document.addEventListener('DOMContentLoaded', function () {
    navbar.start(); // это первым поставь
    startBackgroundImages();
    startScrollUp('scroll-up-platform');
    startSwiperHero();
    pswp.start();
});
