import Vue from 'vue';
import FeedbackForm from "@/platform/components/feedback/FeedbackForm.vue";
import FeedbackFormTrigger from "@/platform/components/feedback/FeedbackFormTrigger.vue";
import { use, registerComponent } from "@/utils/plugins";
/**
 * Подключение формы на страницу. По возможности
 * используйте для `selector` идентификатор, чтобы гарантировать
 * уникальность формы на странице. Если нужно много форм на странице,
 * лучше использовать триггеры.
 */
function mountForm(selector) {
    if (!document.querySelector(selector))
        return false;
    return new Vue({
        render: function (h) { return h(FeedbackForm); }
    }).$mount(selector);
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger">
 *   <feedback-form-trigger title="Feedback"></feedback-form-trigger>
 * </div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
function mountTrigger(selector) {
    if (selector === void 0) { selector = '.js-callback-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ components: { FeedbackFormTrigger: FeedbackFormTrigger } }).$mount(el); });
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger"></div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
function renderTrigger(selector) {
    if (selector === void 0) { selector = '.js-callback-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ render: function (h) { return h(FeedbackFormTrigger); } }).$mount(el); });
}
/**
 * @todo demo
 */
// function createTriggerWidget(): void {
//   const trigger = document.createElement('div')
//   trigger.classList.add('js-callback-form-trigger')
//   document.body.appendChild(trigger)
//   renderTrigger()
// }
/**
 * При глобальной регистрации компонента
 * будет доступен "глобально" на уровне Vue,
 * то есть использовать <feedback-form></feedback-form>
 * в любых Vue компонентах.
 */
var Plugin = {
    install: function (Vue) {
        registerComponent(Vue, FeedbackForm);
    }
};
/**
 * use проверяет window.Vue и
 * регистрирует компонент
 */
use(Plugin);
export default Plugin;
/**
 * Так же экспортируем компонент
 */
export { FeedbackForm, FeedbackFormTrigger, mountForm, mountTrigger, renderTrigger };
