import Vue from 'vue';
import FeedbackForm from "@/site/components/feedback/FeedbackForm.vue";
import FeedbackFormTrigger from "@/site/components/feedback/FeedbackFormTrigger.vue";
/**
 * Подключение формы на страницу. По возможности
 * используйте для `selector` идентификатор, чтобы гарантировать
 * уникальность формы на странице. Если нужно много форм на странице,
 * лучше использовать триггеры.
 */
export function mountForm(selector) {
    if (!document.querySelector(selector))
        return false;
    return new Vue({
        render: function (h) { return h(FeedbackForm); }
    }).$mount(selector);
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger">
 *   <feedback-form-trigger title="Feedback"></feedback-form-trigger>
 * </div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function mountTrigger(selector) {
    if (selector === void 0) { selector = '.js-feedback-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ components: { FeedbackFormTrigger: FeedbackFormTrigger } }).$mount(el); });
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-callback-form-trigger"></div>
 * ```
 *
 * По-умолчанию class - `js-callback-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-callback-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function renderTrigger(selector) {
    if (selector === void 0) { selector = '.js-callback-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ render: function (h) { return h(FeedbackFormTrigger); } }).$mount(el); });
}
export { FeedbackForm, FeedbackFormTrigger, };
