import Vue from 'vue';
import OrderForm from "@/site/components/order/OrderForm.vue";
import OrderFormTrigger from "@/site/components/order/OrderFormTrigger.vue";
/**
 * Подключение формы на страницу. По возможности
 * используйте для `selector` идентификатор, чтобы гарантировать
 * уникальность формы на странице. Если нужно много форм на странице,
 * лучше использовать триггеры.
 */
export function mountForm(selector) {
    if (!document.querySelector(selector))
        return false;
    return new Vue({
        render: function (h) { return h(OrderForm); }
    }).$mount(selector);
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-order-form-trigger">
 *   <order-form-trigger title="order"></order-form-trigger>
 * </div>
 * ```
 *
 * По-умолчанию class - `js-order-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-order-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function mountTrigger(selector) {
    if (selector === void 0) { selector = '.js-order-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ components: { OrderFormTrigger: OrderFormTrigger } }).$mount(el); });
    return elements || [];
}
/**
 * Монтирование триггеров на страницу. Триггер - HTML-элемент, обычно
 * кнопка для открытия формы. Для подключения обычно используется пустой
 * HTML-элемент (div, span).
 *
 * ```html
 * <div class="js-order-form-trigger"></div>
 * ```
 *
 * По-умолчанию class - `js-order-form-trigger`. То есть если
 * запустить без параметров триггер примонтируется на элементы с
 * классом `js-order-form-trigger`.
 *
 * Всегда используйте css-классы, не используйте `id`.
 *
 */
export function renderTrigger(selector) {
    if (selector === void 0) { selector = '.js-order-form-trigger'; }
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (el) { return new Vue({ render: function (h) { return h(OrderFormTrigger); } }).$mount(el); });
    return elements || [];
}
// /**
//  * При глобальной регистрации компонента
//  * будет доступен "глобально" на уровне Vue,
//  * то есть использовать <order-form></order-form>
//  * в любых Vue компонентах.
//  */
// const Plugin = {
//   install(Vue: Vue): void {
//     registerComponent(Vue, OrderForm)
//   }
// }
// /**
//  * use проверяет window.Vue и
//  * регистрирует компонент
//  */
// use(Plugin)
// export default Plugin
/**
 * Так же экспортируем компонент
 */
export { OrderForm, OrderFormTrigger };
