import Vue from 'vue';
import FormCommonMixin from '@/mixins/form-common-mixin';
export default Vue.extend({
    mixins: [FormCommonMixin],
    data: function () {
        return {
            formId: 1,
        };
    }
});
