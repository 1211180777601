/**
 * установка фонового изображения для элемента
 * из атрибута `data-background`.
 *
 * фоновое изображение (background-image)
 * лучше работает для адаптивных блоков
 *
 * конечно дополнительно необходимо
 * добавить css-стили для определения поведения.
 *
 * ```html
 *  <div
 *    class="has-background-image"
 *    data-background="/assets/images/bg.png">
 *    ...
 *  </div>
 * ```
 *
 * рекомендуемый подход
 *
 * <figure class="image has-background-image"
 *  itemscope itemtype="http://schema.org/ImageObject"
 *  data-type="/assets/images/image.jpg">
 *   <a itemprop="contentUrl" href="/assets/images/image.jpg"></a>
 *   <figcaption itemprop="caption description">
 *    Image
 *   </figcaption>
 * </figure>
 *
 */
var BgImages = /** @class */ (function () {
    function BgImages(selector) {
        var _this = this;
        this.selector = selector || '.has-background-image';
        this.imageAttr = 'data-background';
        this.elements = document.querySelectorAll(this.selector);
        this.elements.forEach(function (el) { return _this.parser(el); });
    }
    BgImages.prototype.parser = function (el) {
        var image = el.getAttribute(this.imageAttr);
        if (image && image !== '') {
            el.style.backgroundImage = "url(" + image + ")";
        }
    };
    return BgImages;
}());
export { BgImages };
export function start() {
    return new BgImages('.has-background-image');
}
