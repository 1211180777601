import Vue from 'vue';
import CallbackForm from "@/platform/components/callback/CallbackForm.vue";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";
export default Vue.extend({
    mixins: [FormTriggerMixin],
    methods: {
        openForm: function () {
            this.$buefy.modal.open({
                component: CallbackForm,
                width: 640,
                customClass: 'form-in-modal',
                canCancel: ['x'],
                /**
                 * опция используется для определения
                 * что форма открыта в модальном окне,
                 * это не стандартная опция она добавлена
                 * специально в форму, и отслеживается
                 * при закрытии уведомления об успешной
                 * отправке
                 */
                props: {
                    trigger: true
                }
            });
        }
    }
});
